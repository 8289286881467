<div class="card mb-5">
  <div class="card-body">
    <h1 class="card-title">
      {{ selectedCompetition.category.name | translateCategory | async }} -
      {{
        selectedCompetition.category.date | date : "EEEE - dd/MM" | titlecase
      }}
    </h1>

    <div class="card-text">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-4 col-md-12">
          <div ngbDropdown class="d-inline-block w-100 h-100">
            <button
              *ngIf="event.alert == 'NONE'"
              class="btn btn-outline-secondary w-100 h-100"
              id="alerts"
              ngbDropdownToggle
              translate
            >
              LIVE.MENU.ACTIVE-ALERT
            </button>
            <button
              *ngIf="event.alert == 'REPAIR'"
              class="btn btn-gradient-warning w-100 h-100"
              id="alerts"
              ngbDropdownToggle
              translate
            >
              LIVE.MENU.ARENA-REPAIR
            </button>
            <button
              *ngIf="event.alert == 'EMERGENCY'"
              class="btn btn-gradient-danger w-100 h-100"
              id="alerts"
              ngbDropdownToggle
              translate
            >
              LIVE.MENU.EMERGENCY
            </button>
            <div ngbDropdownMenu aria-labelledby="alerts">
              <button
                ngbDropdownItem
                (click)="toggleAlert('REPAIR')"
                *ngIf="event.alert != 'REPAIR'"
                translate
              >
                LIVE.MENU.ARENA-REPAIR
              </button>
              <button
                ngbDropdownItem
                (click)="toggleAlert('EMERGENCY')"
                *ngIf="event.alert != 'EMERGENCY'"
                translate
              >
                LIVE.MENU.EMERGENCY
              </button>
              <button
                ngbDropdownItem
                (click)="toggleAlert('NONE')"
                *ngIf="event.alert != 'NONE'"
                translate
              >
                LIVE.MENU.DISABLE
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <button
            class="btn w-100 h-100"
            [disabled]="
              selectedCompetition.shuffleCount > 0 ||
              selectedCompetition.wasOpened ||
              selectedCompetition.isActiveTakeOffsList ||
              !isInDateShuffle ||
              isPassedDate ||
              event.isFinished
            "
            (click)="doShuffle()"
            [ngClass]="
              selectedCompetition.shuffleCount > 0 ||
              selectedCompetition.wasOpened ||
              selectedCompetition.isActiveTakeOffsList ||
              !isInDateShuffle ||
              isPassedDate ||
              event.isFinished
                ? 'btn-light disabled'
                : 'btn-outline-primary'
            "
          >
            <i class="mdi mdi-shuffle"></i>
            <span
              >{{ "LIVE.MENU.SHUFFLE" | translate }} X{{
                selectedCompetition.shuffleCount
              }}/{{ 1 }}</span
            >
            <span *ngIf="!isInDateShuffle || isPassedDate || event.isFinished">
              <br />
              <small translate>LIVE.MENU.NEED-TO-BE-IN-DATE-SHUFFLE</small>
            </span>
          </button>
        </div>
        <div class="col-lg-4 col-md-12">
          <div ngbDropdown class="d-inline-block w-100 h-100">
            <button
              class="btn btn-outline-info w-100 h-100"
              id="genList"
              ngbDropdownToggle
              translate
            >
              LIVE.MENU.GENERATE-LIST
            </button>
            <div ngbDropdownMenu aria-labelledby="genList">
              <button
                ngbDropdownItem
                (click)="openChooseModal(content, 'COMPETITOR')"
                translate
              >
                COMPETITOR.SINGLE
              </button>

              <button
                ngbDropdownItem
                (click)="openChooseModal(content, 'JUDGE')"
                translate
              >
                JUDGE
              </button>
              <button
                ngbDropdownItem
                (click)="openChooseModal(content, 'GATE')"
                translate
              >
                GATE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-3 col-md-6" *ngIf="!selectedCompetition.isRank">
          <button
            class="btn mdi w-100 h-100 d-flex justify-content-center align-items-center flex-column"
            [ngClass]="
              !isInDate || isPassedDate || event.isFinished
                ? 'btn-outline-dark mdi-checkbox-blank-outline'
                : isActiveSubscriptions
                ? 'btn-gradient-success mdi-checkbox-marked-outline'
                : 'btn-outline-success mdi-checkbox-blank-outline'
            "
            (click)="setActiveSubscriptions()"
            [disabled]="!isInDate || isPassedDate || event.isFinished"
          >
            <i class="mdi mdi-ticket-account"></i>
            <span translate>
              {{
                isActiveSubscriptions
                  ? "LIVE.MENU.CLOSE-SUBS"
                  : "LIVE.MENU.OPEN-SUBS"
              }}
            </span>
            <span *ngIf="!isInDateShuffle || isPassedDate || event.isFinished">
              <br />
              <small translate>LIVE.MENU.NEED-TO-BE-IN-DATE</small>
            </span>
          </button>
        </div>
        <div class="col-lg-3 col-md-6">
          <button
            class="btn mdi w-100 h-100 d-flex justify-content-center align-items-center flex-column"
            [ngClass]="
              isActiveTakeOffsList
                ? 'btn-gradient-success mdi-checkbox-marked-outline'
                : !selectedCompetition.isShuffled
                ? 'btn-outline-dark mdi-checkbox-blank-outline'
                : 'btn-outline-success mdi-checkbox-blank-outline'
            "
            (click)="setActiveTakeOffsList()"
            [disabled]="isActiveTakeOffsList || !selectedCompetition.isShuffled"
          >
            <i class="mdi mdi-format-list-bulleted"></i>
            <span translate>
              {{
                isActiveTakeOffsList
                  ? "LIVE.MENU.TAKEOFF-LIST-RELEASED"
                  : "LIVE.MENU.RELEASE-TAKEOFF-LIST"
              }}</span
            >
            <span
              *ngIf="!isActiveTakeOffsList && !selectedCompetition.isShuffled"
            >
              <br />
              <small translate>LIVE.MENU.NEED-TO-SHUFFLE</small>
            </span>
          </button>
        </div>
        <div class="col-lg-3 col-md-6">
          <button
            class="btn mdi w-100 h-100 d-flex justify-content-center align-items-center flex-column"
            [ngClass]="
              isActiveCompetition
                ? 'btn-gradient-success mdi-checkbox-marked-outline'
                : !selectedCompetition.isShuffled || !isActiveTakeOffsList
                ? 'btn-outline-dark mdi-checkbox-blank-outline'
                : 'btn-outline-success mdi-checkbox-blank-outline'
            "
            (click)="setActiveCompetition()"
            [disabled]="
              !isActiveCompetition &&
              (!selectedCompetition.isShuffled || !isActiveTakeOffsList)
            "
          >
            <i class="mdi mdi-radio-tower"></i>

            <span translate>
              {{
                isActiveCompetition
                  ? "LIVE.MENU.STOP-LIVE"
                  : "LIVE.MENU.GO-LIVE"
              }}</span
            >
            <span *ngIf="!isActiveCompetition">
              <br *ngIf="!selectedCompetition.isShuffled" />
              <small *ngIf="!selectedCompetition.isShuffled" translate
                >LIVE.MENU.NEED-TO-SHUFFLE
              </small>
              <br *ngIf="!isActiveTakeOffsList" />
              <small *ngIf="!isActiveTakeOffsList" translate
                >LIVE.MENU.NEED-TO-RELEASE
              </small>
            </span>
          </button>
        </div>
        <div class="col-lg-3 col-md-6">
          <button
            class="btn mdi w-100 h-100 d-flex justify-content-center align-items-center flex-column"
            [ngClass]="
              selectedCompetition.isFinished
                ? 'btn-gradient-dark mdi-checkbox-marked-outline'
                : 'btn-outline-success mdi-checkbox-blank-outline'
            "
            (click)="setCompetitionResult()"
            [disabled]="
              selectedCompetition.isFinished ||
              !selectedCompetition.isShuffled ||
              !isActiveTakeOffsList
            "
          >
            <i class="mdi mdi-close-box"></i>
            <span translate>
              {{
                selectedCompetition.isFinished
                  ? "LIVE.MENU.CATEGORY-FINISHED"
                  : "LIVE.MENU.FINISH-CATEGORY"
              }}
            </span>
            <span *ngIf="!isActiveCompetition">
              <br *ngIf="!selectedCompetition.isShuffled" />
              <small *ngIf="!selectedCompetition.isShuffled" translate
                >LIVE.MENU.NEED-TO-SHUFFLE
              </small>
              <br *ngIf="!isActiveTakeOffsList" />
              <small *ngIf="!isActiveTakeOffsList" translate
                >LIVE.MENU.NEED-TO-RELEASE
              </small>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" translate>CHOOSELIST</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('landscape')"
        translate
      >
        HORIZONTAL
      </button>
      <button
        type="button"
        class="btn btn-light"
        (click)="modal.close('portrait')"
        translate
      >
        VERTICAL
      </button>
    </div>
  </ng-template>
</div>
