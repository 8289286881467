//<reference path="typings.d.ts"/>
declare var proc: any;
export const environment = {
  production: true,
  adminId: [
    "6019bb70d97bbb4db0ce665a",
    "6089b2dbfd1b96002837bc96",
    "62767cc0a733ac0016016e8c",
  ],
  apiUrl: "https://api.horsecs.com.br",
  langs: [
    { name: "Português", type: "pt" },
    { name: "Español", type: "es" },
    { name: "English", type: "en" },
  ],
};
